@charset "UTF-8";
/*!
 * Select2-to-Tree CSS 1.1.1
 * https://github.com/clivezhg/select2-to-tree
 */
.s2-to-tree * {
  box-sizing: border-box; }

.s2-to-tree .select2-results__option.l1 {
  margin-left: 0.6em;
  font-size: 1em; }

.s2-to-tree .select2-results__option.l2 {
  margin-left: 1.4em;
  font-size: 0.95em; }

.s2-to-tree .select2-results__option.l3 {
  margin-left: 2.2em;
  font-size: 0.91em; }

.s2-to-tree .select2-results__option.l4 {
  margin-left: 3.0em;
  font-size: 0.87em; }

.s2-to-tree .select2-results__option.l5 {
  margin-left: 3.8em;
  font-size: 0.83em; }

.s2-to-tree .select2-results__option.l6 {
  margin-left: 4.5em;
  font-size: 0.8em; }

.s2-to-tree .select2-results__option.l7 {
  margin-left: 5.3em;
  font-size: 0.77em; }

.s2-to-tree .select2-results__option.l8 {
  margin-left: 6.0em;
  font-size: 0.75em; }

.s2-to-tree .select2-dropdown[dir*="rtl"] .select2-results__option.l1 {
  margin-right: 0.6em; }

.s2-to-tree .select2-dropdown[dir*="rtl"] .select2-results__option.l2 {
  margin-right: 1.4em; }

.s2-to-tree .select2-dropdown[dir*="rtl"] .select2-results__option.l3 {
  margin-right: 2.2em; }

.s2-to-tree .select2-dropdown[dir*="rtl"] .select2-results__option.l4 {
  margin-right: 3.0em; }

.s2-to-tree .select2-dropdown[dir*="rtl"] .select2-results__option.l5 {
  margin-right: 3.8em; }

.s2-to-tree .select2-dropdown[dir*="rtl"] .select2-results__option.l6 {
  margin-right: 4.5em; }

.s2-to-tree .select2-dropdown[dir*="rtl"] .select2-results__option.l7 {
  margin-right: 5.3em; }

.s2-to-tree .select2-dropdown[dir*="rtl"] .select2-results__option.l8 {
  margin-right: 6.0em; }

.s2-to-tree li.select2-results__option.non-leaf .expand-collapse:hover {
  color: red;
  cursor: pointer; }

.s2-to-tree li.select2-results__option.non-leaf .expand-collapse:before {
  /* no :: in IE8 */
  content: "+";
  position: absolute;
  left: -0.35em;
  top: 0.1em;
  font-size: larger; }

.s2-to-tree .select2-dropdown[dir*="rtl"] li.select2-results__option.non-leaf .expand-collapse:before {
  right: -0.35em; }

.s2-to-tree li.select2-results__option.non-leaf.opened .expand-collapse:before {
  content: "−"; }

.s2-to-tree .select2-results__option[aria-disabled="true"] .expand-collapse {
  color: black; }

.s2-to-tree .item-label {
  display: inline-block;
  margin-left: 0.5em;
  padding: 3px;
  width: calc(100% - 11px);
  /* IE8 will fit the content */ }

.s2-to-tree .select2-dropdown[dir*="rtl"] .item-label {
  margin-right: 0.5em; }

.s2-to-tree li.select2-results__option {
  position: relative;
  padding: 0px;
  height: auto;
  overflow-y: hidden; }

.s2-to-tree li.select2-results__option[data-pup] {
  display: none; }

.s2-to-tree li.select2-results__option[data-pup].showme {
  display: block;
  overflow-y: visible; }

.s2-to-tree.select2-container .select2-results__option--highlighted[aria-selected] > span.item-label {
  background-color: #5897fb;
  color: white; }

.s2-to-tree.select2-container li.select2-results__option[aria-selected="true"] > span.item-label {
  background-color: #ddd; }

.s2-to-tree.select2-container li.select2-results__option--highlighted[aria-selected],
.s2-to-tree.select2-container li.select2-results__option[aria-selected="true"] {
  background-color: inherit;
  color: inherit; }

.s2-to-tree li.select2-results__option.l1 {
  display: block;
  overflow-y: visible; }

.s2-to-tree.searching-result li.select2-results__option {
  height: auto;
  display: block; }
